function notify(message, type)
{
     noty({
            layout: 'bottomRight',
            type: type,
            theme: 'relax',
            text: message,
            timeout  : 3000,
            animation: {
            open: 'animated bounceInRight',
            close: 'animated bounceOutRight',
        }
    });
}